<template>
  <div>
    <a-form layout="inline" :form="form1" @submit="handleSearch">
      <a-form-item>
        <a-input
          v-decorator="['key']"
          placeholder="请输入账号或昵称"
          autocomplete="off"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-select
          v-decorator="['get_way']"
          class="sm-select"
          placeholder="积分获取方式"
          showSearch
          @change="handleSelectChange"
        >
          <a-select-option
            v-for="(item, index) in pointOptions"
            :key="index"
            :value="item.get_way_method"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit"> 搜索 </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="handleClear" html-type="button">
          刷新
        </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: true }"
    >
      <span slot="quantity" slot-scope="text">
        <span>{{ text }}积分</span>
      </span>
      <span slot="get_way" slot-scope="text">
        <span v-if="text === 'login'">登录</span>
        <span v-else-if="text === 'pay'">购买</span>
        <span v-else-if="text === 'registered'">注册</span>
        <span v-else-if="text === 'modify'">修改</span>
        <span v-else-if="text === 'binding_phone_number'">绑定手机</span>
        <span v-else-if="text === 'message'">采用留言</span>
        <span v-else-if="text === 'binding_email'">绑定邮箱</span>
        <span v-else-if="text === 'invite_new_users'">邀请新用户</span>
        <span v-else-if="text === 'user_upload_document'">上传文档</span>
        <span v-else-if="text === 'user_upload_software'">上传软件</span>
      </span>
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20"
    />
  </div>
</template>

<script>
import { PointRecordModel } from "@/models/pointRecord";
import Pagination from "../../components/Pagination";
import formatTime from "../../utils/formatTime";
import userNameDisplay from "../../utils/userNameDisplay";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

const pointRecordModel = new PointRecordModel();
import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("zh-cn");

const columns = [
  {
    title: "获取数量",
    dataIndex: "quantity",
    key: "quantity",
    scopedSlots: { customRender: "quantity" },
  },
  {
    title: "获取方式",
    dataIndex: "get_way",
    key: "get_way",
    scopedSlots: { customRender: "get_way" },
  },
  {
    title: "获取时间",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "用户账号",
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "用户昵称",
    dataIndex: "nick_name",
    key: "nick_name",
  },
];
export default {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      loading: false,
      total: 0,
      locale,
      current: 1,
      pointOptions: [
        {
          get_way_method: "login",
          name: "登录",
        },
        {
          get_way_method: "pay",
          name: "购买",
        },
        {
          get_way_method: "binding_phone_number",
          name: "绑定手机",
        },
        {
          get_way_method: "binding_email",
          name: "绑定邮箱",
        },
        {
          get_way_method: "message",
          name: "采用留言",
        },
        {
          get_way_method: "registered",
          name: "注册",
        },
        {
          get_way_method: "modify",
          name: "修改",
        },
         {
          get_way_method: "invite_new_users",
          name: "邀请新用户",
        },
        {
          get_way_method: "",
          name: "全部",
        },
      ],
    };
  },

  created() {
    this.queryPointGainRecordList();
  },

  methods: {
    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          this.queryPointGainRecordList(key, values.get_way, 1);
        }
      });
    },

    handleClear() {
      this.form1.resetFields();
      this.queryPointGainRecordList();
    },

    handleChange(page) {
      this.current = page;
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          this.queryPointGainRecordList(key, values.get_way, this.current);
        }
      });
    },

    queryPointGainRecordList(key, getWay, page) {
      this.loading = true;
      pointRecordModel.index("gain", key, getWay, page).then((res) => {
        if (res.code == 0) {
          this.current = page;
          res.result.map((item, index) => {
            item.key = index;
            item.user_name = userNameDisplay(item);
            item.created_at = formatTime(item.created_at);
            return item;
          });
          this.data = res.result;
          this.loading = false;
          this.total = res.total;
        }
      });
    },
  },

  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
</style>
